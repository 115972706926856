var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-popup",
    {
      attrs: {
        "hide-close-btn": "",
        show: _vm.show,
        "has-buttons": false,
        "has-title": false,
        "has-padding": false,
        "left-btn": _vm.leftBtn,
        "max-width": "500",
        "has-body-content": "",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "the-enkrypt-popup popup-container py-10 px-6 px-sm-15",
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-button",
              attrs: { icon: "" },
              on: { click: _vm.close },
            },
            [
              _c("v-icon", { attrs: { color: "white" } }, [
                _vm._v("mdi-close"),
              ]),
            ],
            1
          ),
          _c("div", {
            directives: [
              {
                name: "lottie",
                rawName: "v-lottie",
                value: _vm.surpriseLottie,
                expression: "surpriseLottie",
              },
            ],
            staticClass: "lottie",
          }),
          _c("div", { staticClass: "text-center mb-6" }, [
            _c("img", {
              staticClass: "enkrypt-logo mb-4",
              attrs: { src: _vm.white, alt: "enkrypt logo" },
            }),
            _c("div", { staticClass: "mew-heading-3 heading" }, [
              _vm._v(" Introducing our multi-chain wallet extension! "),
            ]),
          ]),
          _c(
            "v-carousel",
            {
              staticClass: "carousel-images",
              attrs: {
                cycle: "",
                height: "100%",
                "hide-delimiters": "",
                "show-arrows": false,
              },
            },
            _vm._l(_vm.nfts, function (nft, i) {
              return _c("v-carousel-item", { key: i }, [
                _c("img", {
                  staticClass: "main-image",
                  attrs: { src: _vm.nfts[i].src, alt: "nft-image" },
                }),
              ])
            }),
            1
          ),
          _c("div", { staticClass: "px-2" }, [
            _c(
              "div",
              {
                staticClass: "mew-heading-4 mt-5 mb-4 black--text",
                staticStyle: { "line-height": "24px !important" },
              },
              [
                _vm._v(" Easily access all your favorite apps across "),
                _c("br"),
                _vm._v("Ethereum and Polkadot chains. "),
              ]
            ),
            _c(
              "div",
              _vm._l(_vm.checkedText, function (cText) {
                return _c(
                  "div",
                  { key: cText, staticClass: "d-flex align-center mb-2" },
                  [
                    _c("div", { staticClass: "purple-dots mr-3" }),
                    _c("div", { staticClass: "black--text" }, [
                      _vm._v(_vm._s(cText)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column-reverse flex-md-row align-center justify-space-around mt-6",
            },
            [
              _c(
                "mew-button",
                {
                  staticClass: "mt-1 mt-md-0",
                  attrs: {
                    "color-theme": "#939fb9",
                    "btn-style": "transparent",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.openHelpCenter.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-medium",
                      staticStyle: {
                        "letter-spacing": "0.5px",
                        "text-transform": "none",
                      },
                    },
                    [_vm._v(" Learn more ")]
                  ),
                ]
              ),
              _c(
                "mew-button",
                {
                  staticClass: "extension-btn",
                  staticStyle: { "border-radius": "40px !important" },
                  attrs: { "color-theme": "#7E44F2" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.install.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.browser !== "default"
                    ? _c("img", {
                        staticClass: "chrome-logo mr-3",
                        attrs: { src: _vm.browserLogo, alt: "chrome" },
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "letter-spacing": "0.5px",
                        "text-transform": "none",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.text) + " ")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }