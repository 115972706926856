// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/backgrounds/bg-enkrypt.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".popup-container[data-v-8508cc76] {\n  position: relative;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: top -280px center;\n  background-size: 700px;\n  margin-top: -20px;\n  position: relative;\n  overflow: hidden;\n}\n.lottie[data-v-8508cc76] {\n  position: absolute;\n  top: 0px;\n  left: 0;\n  z-index: 0;\n}\n.heading[data-v-8508cc76] {\n  z-index: 1;\n  color: #ffffff;\n  letter-spacing: 0.15px;\n}\n.carousel-box[data-v-8508cc76] {\n  border-radius: 8px;\n  background: white;\n  -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.27);\n          box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.27);\n  overflow: hidden;\n}\n.purple-dots[data-v-8508cc76] {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  background-color: #974bff;\n}\n.main-image[data-v-8508cc76] {\n  width: 100%;\n  height: 100%;\n}\n.content[data-v-8508cc76] {\n  width: 320px;\n  text-align: left;\n  color: #192133;\n  font-size: 15px;\n  line-height: 24px;\n  font-weight: 500;\n}\n.extension-btn span[data-v-8508cc76] {\n  font-size: 18px;\n  font-weight: 500;\n}\n.chrome-logo[data-v-8508cc76] {\n  width: 25px;\n  height: 25px;\n}\n.close-button[data-v-8508cc76] {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  z-index: 9;\n}\n.carousel-images[data-v-8508cc76] {\n  overflow: hidden;\n  border-radius: 5px;\n}", ""]);
// Exports
module.exports = exports;
